
body {
    // font-family: $font-light;
    @include fontLight;
}

nav {
    position: relative;

    h2 {
        font-size: 24px;
        margin-bottom: 5px;
        //font-family: $font-medium;
        @include fontMedium;
    }

    h3 {
        font-size: 18px;
        margin-top: 0;
        //font-family: $font-light;
        @include fontLight;
        margin-bottom: 20px;
    }

    #logo {
        background: url('../images/logo.png');
        background-repeat: no-repeat;
        height: 145px;
        width: 145px;
        text-indent: -10000px;
        margin: 0;
    }

    #language-picker {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 99;
        border-bottom: 1px solid;
        border-right: 1px solid;
        border-left: 1px solid;
        border-color: $idle-color;
        padding: 2px 15px 2px 10px;
        float: right;

        .current {
            font-size: 12px;
            color: $idle-color;
            text-decoration: none;

            .fa {
                font-size: 14px;
            }
        }

        a {
            cursor: pointer;
        }
    }

    #user-auth {
        position: absolute;
        right: 0;
        z-index: 99;
        border-bottom: 1px solid;
        border-right: 1px solid;
        border-left: 1px solid;
        border-color: $idle-color;
        padding: 2px 15px 2px 10px;

        .dropdown-toggle {
            &:hover {
                text-decoration: none;
            }
        }

        .dropdown-menu {
            width: calc(100% + 2px);
            min-width: 250px !important;
            border-radius: 0;
            margin-left: -1px;
            margin-top: 0;
            padding: 0;
            right: -1px;
            left: auto;

            .login {
                padding: 1.5rem;

                button[type="submit"] {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .spinner {
                        width: 16px;
                        margin-left: 5px;
                    }
                }
            }

            .dropdown-link {
                padding-top: 5px;
                padding-bottom: 5px;
            }
        }
    }



    #add-to-home {
        display: none;
    }


    #breadcrumbs {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
            float: left;
            border-right: 1px solid $idle-color;
            color: $idle-color;
            padding-right: 10px;
            margin-right: 10px;

            &:last-child {
                border: none !important;
            }

            &.active {
                color: #000;
            }
        }
    }

    .menu-spacer {
        height: 55px;
    }

    #menu {
        a {
            color: $main-color;
            //font-family: $font-medium;
            @include fontMedium;
            text-decoration: none;
            cursor: pointer;
        }
    }
}

#main-content {
    margin-top: 50px;
}

#print-logo {
    background: url('../images/logo.png') !important;
    background-repeat: no-repeat;
    height: 100px;
    width: 100px;
    background-size: 100% 100% !important;
    -webkit-print-color-adjust: exact;
}

.row-no-gutters [class*=col-] {
    padding-right: 0;
    padding-left: 0;
}


label {
    font-size: 14px;
    color: #000;
    //font-family: $font-bold;
    @include fontBold;
}

.text-danger {
    color: #dc3545
}

.error {
    margin-bottom: 10px;
    font-weight: bold;

    i {
        margin-right: 5px;
    }
}

.d-block {
    display: block;
}


.radio {
    label {
        background-repeat: no-repeat;
        background-position: 0px center;
        background-image: url(../images/radio_off.png);
        padding-left: 25px;
        height: 18px;
        display: inline-block;
        line-height: 18px;
        margin-right: 20px;
    }

    input[type=radio] {
        display: none;
    }

    input[type="radio"]:checked + label {
        background-image: url(../images/radio_on.png);
    }
}

.tile {
    outline: 2px solid #777;
    background-color: white;
    text-align: center;
    width: 100%;
    //height: 100px;
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    box-shadow: 1px 10px 20px rgba(0, 0, 0, .1);
    border-radius: 8px;
    opacity: 0.9;
    transition: 200ms ease-out;
    box-sizing: content-box;
    cursor: pointer;
    margin-bottom: 0;

    @media screen and (max-width: 767.99px) {
        width: 120px;
    }

    &.selected {
        outline: 2px solid #009aca;
        opacity: 1;
        background-color: #009aca;
        color: white;

        .checkbox-tile {
            transform: scale(1);
            opacity: 1;
            background-color: #fff;
            border-color: #009aca;
        }

        label {
            color: white;
        }
    }

    input {
        clip: rect(0 0 0 0);
        -webkit-clip-path: inset(100%);
        clip-path: inset(100%);
        height: 1px;
        overflow: hidden;
        position: absolute;
        white-space: nowrap;
        width: 1px;
    }

    .checkbox-tile {
        position: absolute;
        display: block;
        width: 25px;
        height: 25px;
        border: 2px solid #b5bfd9;
        background-color: #fff;
        border-radius: 50%;
        top: -10px;
        right: -10px;
        opacity: 0;
        transform: scale(0);
        transition: 0.25s ease;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='192' height='192' fill='%23009aca' viewBox='0 0 256 256'%3E%3Crect width='256' height='256' fill='none'%3E%3C/rect%3E%3Cpolyline points='216 72.005 104 184 48 128.005' fill='none' stroke='%23009aca' stroke-linecap='round' stroke-linejoin='round' stroke-width='32'%3E%3C/polyline%3E%3C/svg%3E");
        background-size: 12px;
        background-repeat: no-repeat;
        background-position: 50% 50%;
    }

    .title {
        margin-bottom: 0;
        margin-top: 0;
        font-size: 16px;
        font-weight: bold;
    }
}


.notes {
    padding: 10px 20px 20px 20px;
    border: 1px solid #ccc;
}

footer {
    min-height: 50px;
}
