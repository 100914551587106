.dropdown-menu {
    min-width: inherit !important;
}

th {
    font-weight: bold;
    //font-family: $font-bold;
    @include fontBold;
}

.parsley-error {
    border: 1px solid #F38080 !important;
}

.parsley-errors-list {
    margin: 0;
    padding: 0;
    font-size: 11px;
    padding-left: 20px;
}
