

.modal {
    h3 {
        margin-top: 10px;
        margin-bottom: 20px;
        border-bottom: 1px solid #ccc;
        padding-bottom: 20px;
        position: relative;

        button {
            position: absolute;
            right: 0;
            background: transparent;
            border: none;
        }
    }

    h5 {
        //font-family: $font-bold;
        @include fontBold;
    }


}

#faqModal {
    .modal-dialog {
        width: 900px;
    }
}

#specialModal {
    .modal-dialog {
        width: 700px;
    }
}

#legendModal {
    .modal-dialog {
        width: 900px;
    }
}


#sendModal {
    .modal-dialog {
        width: 400px;
    }
}
