$idle-color: #CBCCCB;
$main-color: #009ACA;
$font-medium: 'HelveticaNeueW01-55Roma';
$font-light: 'HelveticaNeueW01-45Ligh';
$font-bold: 'HelveticaNeueW01-75Bold';

@mixin fontLight {
    font-family: Helvetica, Arial, "Lucida Grande", sans-serif;
    font-weight: 300;
}

@mixin fontMedium {
    font-family: Helvetica, Arial, "Lucida Grande", sans-serif;
    font-weight: 500;
}

@mixin fontBold {
    font-family: Helvetica, Arial, "Lucida Grande", sans-serif;
    font-weight: 700;
}
