.slider {

	&.ui-slider {
		background-color: #CBCCCB;
		height: 16px;
		border-radius: 20px;
	}

	.ui-slider-range {
		background-color: #808080;
	}

	.ui-slider-handle {
		background-color: red;
		top: 0;
		background-color: #F2F1F0;
    	border: 1px solid #acacac;
		border-radius: 50%;
		outline: none !important;
		cursor: pointer;

		&:active {
			box-shadow: 0px 0px 5px rgba(0,0,0,0.2);
			background-color: #e3e1de;
			height: 24px;
		    width: 24px;
		    margin-top: -4px;
		}
	}
}

.slider-info.form-control {
	font-size: 30px;
	color: $main-color;
	padding-left: 0;
	//font-family: $font-bold;
	@include fontBold;
	line-height: 24px;
	width: 85%;
	padding: 0;
	height: auto;
	//border: none;
	//box-shadow: none;
	text-align: right;
	display: inline-block;

	& + small {
		display: inline-block;
		color: $main-color;
		font-size: 11px;
		margin-left: 4px;
		//font-family: $font-medium;
		@include fontMedium;
	}
}

.slider-text {
	font-size: 11px;
	color: $idle-color;
	margin-bottom: 2px;
}

.question {
	cursor: help;
}
