.table-gate {
    min-width: 1000px;

    thead > tr > th {
        border: none !important;
    }

    .bordered {
        th, td {
            border: 1px #ddd solid;

            &:first-child {
                border-left: none;
            }

            &:last-child {
                border-right: none;
            }
        }
    }

    #unit, #heading {
        border-top: none;
        border-bottom: none;
        
        tr {
            background-color: transparent !important;

            th, td {
                border-top: none;
                border-bottom: none;
            }
        }
    }

    tr > th,td {
        &:nth-last-child(-n+5) {
            font-size: 12px;
        }
    }
}

.gate {
    td {
        padding: 0 !important;
        border: none !important;
        background-color: #fff !important;
    }

    tr:last-child td {
        height: 27px;
    }
}

#ruler {
    border: none !important;

    tr {
        background: transparent !important;

        td {
            border: none !important;
            background: transparent !important;
            padding-left: 0px !important;
            padding-right: 0px !important;
            height: 30px;
            padding-top: 10px;

            .line {
                border-bottom: 1px solid #636463;
                width: 100%;
                position: relative;
                height: 4px;

                .dot-right {
                    position: absolute;
                    top: 0px;
                    right: 0px;
                    background: url(../images/gate/dot-right.png) no-repeat !important;
                    display: block;
                    width: 4px;
                    height: 8px;
                    -webkit-print-color-adjust: exact;
                }

                .dot-left {
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    background: url(../images/gate/dot-left.png) no-repeat !important;
                    display: block;
                    width: 4px;
                    height: 8px;
                    -webkit-print-color-adjust: exact;
                }
            }
        }
    }
}

#unit {
    small {
        &:before {
            content: "(";
        }
        
        &:after {
            content: ')';
        }        
    }
}

#c1 {
    width: 100%;
    height: 243px;
    background: url(../images/gate/c1.png) repeat-x !important;
    -webkit-print-color-adjust: exact;
}

#c2 {
    width: 64px;
    height: 243px;
    background: url(../images/gate/c2.png) no-repeat !important;
    -webkit-print-color-adjust: exact;
}

#c3a {
    position: relative;
    width: 100%;
    height: 30px;
    background: url(../images/gate/c3a.png) repeat-x !important;
    -webkit-print-color-adjust: exact;
}

#c3b {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 1px;
    height: 100%;
    background: url(../images/gate/c3b.png) repeat-y !important;
    z-index: 999;
    -webkit-print-color-adjust: exact;
}

#c4 {
    position: relative;
    width: 100%;
    height: 243px;
    background: url(../images/gate/c4.png) repeat-x !important;
    -webkit-print-color-adjust: exact;
}

#c5 {
    width: 100%;
    height: 18px;
    background: url(../images/gate/c5.png) repeat-x !important;
    -webkit-print-color-adjust: exact;
}

#c6 {
    width: 20px;
    height: 243px;
    background: url(../images/gate/c6.png) repeat-x !important;
    -webkit-print-color-adjust: exact;
}

#c7 {
    width: 100%;
    height: 243px;
    background-image: url(../images/gate/c7.png) !important;
    -webkit-print-color-adjust: exact;
    background-repeat: no-repeat;
    background-position: left top;

    &.tirante {
        background-image: url(../images/gate/c7a.png) !important;

        &.post {
            background-image: url(../images/gate/c7a_post.png) !important;
        }
    }
    &.pieno {
        background-image: url(../images/gate/c7b.png) !important;

        &.post {
            background-image: url(../images/gate/c7b_post.png) !important;
        }
    }
}

#c8a {
    position: relative;
    width: 100%;
    height: 30px;
    background: url(../images/gate/c8a.png) repeat-x !important;
    -webkit-print-color-adjust: exact;
}

#c8b {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 1px;
    height: 100%;
    background: url(../images/gate/c8b.png) repeat-y !important;
    z-index: 999;
    -webkit-print-color-adjust: exact;
}

#c9a {
    position: relative;
    width: 100%;
    height: 30px;
    background: url(../images/gate/c8a.png) repeat-x !important;
    -webkit-print-color-adjust: exact;
}

#c9b {
    position: absolute;
    bottom: 0;
    width: 51px;
    height: 30px;
    background: url(../images/gate/c9.png) no-repeat !important;
    -webkit-print-color-adjust: exact;
}

#c9b2 {
    position: absolute;
    bottom: 0;
    width: 51px;
    height: 30px;
    background: url(../images/gate/c9b.png) no-repeat !important;
    -webkit-print-color-adjust: exact;
}

#c10 {
    width: 100%;
    height: 30px;
    background: url(../images/gate/c8a.png) repeat-x !important;
    -webkit-print-color-adjust: exact;
}

#c11a {
    position: relative;
    width: 100%;
    height: 30px;
    background: url(../images/gate/c8a.png) repeat-x !important;
    -webkit-print-color-adjust: exact;
}

#c12a {
    position: relative;
    width: 100%;
    height: 243px;
    background: url(../images/gate/c12.png) repeat-x !important;
    -webkit-print-color-adjust: exact;
}

#c13a {
    position: relative;
    width: 100%;
    height: 30px;
    background: url(../images/gate/c8a.png) repeat-x !important;
    -webkit-print-color-adjust: exact;
}

#c12b {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 1px;
    height: 100%;
    background: url(../images/gate/c12b.png) repeat-y !important;
    z-index: 999;
    -webkit-print-color-adjust: exact;
}

#c13b {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 1px;
    height: 100%;
    background: url(../images/gate/c13b.png) repeat-y !important;
    z-index: 999;
    -webkit-print-color-adjust: exact;
}

#c14 {
    position: relative;
    width: 100%;
    height: 15px;
    background: url(../images/gate/c14.png) no-repeat !important;
    -webkit-print-color-adjust: exact;
}

#c15 {
    position: relative;
    width: 100%;
    height: 15px;
    background: url(../images/gate/c15b.png) no-repeat !important;
    -webkit-print-color-adjust: exact;

    &.wheel {
        background: url(../images/gate/c15.png) no-repeat !important;
    }
}

#c16 {
    position: relative;
    width: 100%;
    height: 15px;
    overflow: hidden;
    background: url(../images/gate/c16b.png) repeat-x !important;
    -webkit-print-color-adjust: exact;

    &.wheel {
        background: url(../images/gate/c16.png) no-repeat !important;
    }

    #c16a {
        left: 0;
        background: url(../images/gate/c12b.png) repeat-y !important;
        height: 100%;
        top: 7px;
        position: absolute;
        z-index: 999;
        width: 1px;
    }
}

#c17 {
    position: relative;
    width: 100%;
    height: 15px;
    background: url(../images/gate/c17b.png) repeat !important;
    -webkit-print-color-adjust: exact;

    &.wheel {
        background: url(../images/gate/c17.png) repeat !important;
    }
}

#c18 {
    position: relative;
    width: 100%;
    height: 15px;
    background: url(../images/gate/c19.png) repeat !important;
    -webkit-print-color-adjust: exact;

    #c18a {
        position: absolute;
        width: 233px;
        height: 15px;
        top: 0;
        left: 0;
        background: url(../images/gate/c18b.png) repeat-x !important;
        -webkit-print-color-adjust: exact;

        &.wheel {
            background: url(../images/gate/c18.png) no-repeat !important;
        }

        &.no-wheel {
            background: url(../images/gate/c18_post.png) no-repeat !important;

            &.full {
                background: url(../images/gate/c18_post_full.png) no-repeat !important;
            }

        }
    }
}

#c19 {
    position: relative;
    width: 100%;
    height: 15px;
    background: url(../images/gate/c19.png) repeat-x !important;
    -webkit-print-color-adjust: exact;

    #c19b {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 1px;
        height: 100%;
        background: url(../images/gate/c12b.png) repeat-y !important;
        z-index: 999;
        -webkit-print-color-adjust: exact;
    }
}

#bb2 {
    position: relative;
    width: 272px;
    height: 22px;

    span {
        position: absolute;
        top: -12px;
        left: -16px;
        right: 0px;
        bottom: 0px;
        border-left: 2px dashed #636463;
        display: block;
        border-bottom: 2px dashed #636463;
        border-right: 2px dashed #636463;
    }
}

#weight {
    width: 94px;
    height: 108px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -54px;
    margin-left: -47px;
    background: url(../images/weight.png) no-repeat !important;
    text-align: center;
    padding-top: 44px;
    font-size: 28px;
    //font-family: $font-bold;
    @include fontBold;
    color: $main-color;
    -webkit-print-color-adjust: exact;
}

#opening {
    height: 24px;
    position: absolute;
    top: -10px;
    left: 10px;
    right: 10px;
    background: url(../images/arrow.png) no-repeat left center !important;
    text-align: center;
    color: $main-color;
    -webkit-print-color-adjust: exact;

    div {
        position: absolute;
        top: 0px;
        right: 0px;
        width: 13px;
        height: 24px;
        background: url(../images/arrow_end.png) no-repeat right center !important;
        -webkit-print-color-adjust: exact;
    }

    span {
        background-color: #fff !important;
        padding: 0px 15px;
        font-size: 20px;
        line-height: 20px;
        //font-family: $font-bold;
        @include fontBold;
        -webkit-print-color-adjust: exact;
    }
}

.lateral-forces {
    margin-top: 30px;
    text-align: center;
    font-weight: bold;
}


.materials {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    margin-top: 30px;
    margin-right: 0;
    margin-left: 0;

    .materials-title {
        font-weight: bold;
        font-size: 16px;

    }

    .materials-wrapper {
        width: 100%;
        display: flex;
        gap: 32px;
        align-items: center;
        flex-wrap: nowrap;

    }

    @media screen and (max-width: 767.99px) {
        flex-direction: column;
        gap: 16px;

    }

}

@media screen and (max-width: 767.99px) {
    .filters-wrapper {
        display: flex;
        align-items: baseline;
        justify-content: space-around;
    }
}
