.mobile {

    /* Nav */
    nav {

        h2 {
            font-size: 20px;
        }

        h3 {
            font-size: 13px;
        }

        #logo {
            height: 110px;
            width: 110px;
            background-size: 110px 110px;
        }
    }

    #menu {
        a {
            display: none;
        }
    }

    #faqModal {
        .modal-dialog {
            width: 100%;
            margin: 0;
        }
    }

    #specialModal {
        .modal-dialog {
            width: 100%;
            margin: 0;
        }
    }

    #legendModal {
        .modal-dialog {
            width: 100%;
            margin: 0;
        }
    }

    #language-picker {
        position: relative;
        border: none;
        float: none;
        text-align: center;
        padding: 10px;

        .dropdown-menu {
            min-width: 160px !important;
        }
    }

    #user-auth {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        position: relative;
        border: none;
        float: none;
    }


    #add-to-home {
        display: block;
        font-size: 12px;
        color: $idle-color;
        line-height: 20px;
        text-align: center;
        padding: 10px;
    }

    #mobile-header {
        margin-bottom: 10px;

        > div {
            border: 1px $idle-color solid;
            height: 40px;
        }
    }
}

.tablet {

    /* Nav */
    nav {
        #breadcrumbs {
            li {
                float: none;
                display: block;
                border: none;
            }
        }
    }

}
