
#options-breadcrumbs {

    .step {
        position: relative;
        opacity: 0.3;

        img {
            position: absolute;
            right: 40px;
            top: 20px;
        }

        &.active {
            opacity: 1;
        }

        .radio {
            label {
                //font-family: $font-light;
                @include fontLight;
            }
        }
    }
}

.table-options {
    td {
        vertical-align: middle !important;
    }
}
