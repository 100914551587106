.banner {
    position: fixed;
    background-color: $main-color;
    color: white;
    width: 100%;
    left: 0;
    padding: 30px;
    padding-right: 60px;
    z-index: 5;

    &.top {
        top: 0;
    }

    &.bottom {
        bottom: 0;
    }

    .close {
        font-size: 50px;
        position: absolute;
        top: 0px;
        right: 15px;
        opacity: .5;
        cursor: pointer;

        &:hover {
            opacity: .9;
        }
    }
}